  .App {
    max-width: 1200px;
    margin: 20px auto;
    padding: 30px;
    background-color: #f7f9fc;
    border-radius: 10px;
  }
  
  form {
    margin-top: 20px;
  }
  
  table {
    margin: 20px auto;
  }
  